import React, { useState, useEffect } from "react";
import { MdPhone } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi"; // Icon for emergency calls
import LoadingPage from "../Container/LoadingPage";
import ErrorPage from "../Container/ErrorPage";
import { AiFillAlert } from "react-icons/ai";

function ChildLocketInfoPage() {
  const [childInfo, setChildInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // For demonstration, we are using dummy data. Replace with actual API call when available.
  useEffect(() => {
    // Simulating API call with dummy data
    const fetchData = async () => {
      try {
        setTimeout(() => {
          const dummyData = {
            kidName: "John Doe",
            fatherName: "Michael Doe",
            fatherNumber: "9876543210",
            motherName: "Jane Doe",
            motherNumber: "9876543211",
            kidAge: 8,
            medicalConditions: ["Asthma"],
            allergies: ["Peanuts", "Dust"],
            schoolName: "Sunrise Elementary School",
            class: "3rd Grade",
            otherName: "Uncle Steve",
            otherNumber: "9876543212",
            address: "1234 Main St, Hometown",
            bloodGroup: "O+",
          };
          setChildInfo(dummyData);
          setLoading(false);
        }, 1000); // Simulating delay for API call
      } catch (error) {
        setError("Failed to fetch child information.");
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading)
    return (
      <div className="text-center text-lg">
        <LoadingPage />
      </div>
    );
  if (error)
    return (
      <div className="text-center text-lg text-red-500">
        <ErrorPage />
      </div>
    );

  return (
    <div className="bg-[#00133e] min-h-screen px-4 py-6">
      <h1 className="text-3xl font-bold text-white text-center mb-6 flex items-center justify-center gap-2">
        <span className="relative 0">
          <AiFillAlert className="h-10 w-10 animate-pulse text-red-600" />
        </span>
        <span className="text-red-600 animate-pulse">Emergency</span> O
        <span className="text-green-600">D</span>
      </h1>
      <h2 className="text-2xl font-bold text-white text-center mb-6 flex items-center justify-center gap-2">
        Child Locket Information
      </h2>

      {childInfo && (
        <div className="space-y-4 text-sm">
          <p>
            <span className="font-semibold text-blue-300">Child's Name:</span>{" "}
            <span className="font-semibold text-white">
              {childInfo.kidName}
            </span>
          </p>
          <p>
            <span className="font-semibold text-green-300">Age:</span>{" "}
            <span className="font-semibold text-white">{childInfo.kidAge}</span>
          </p>
          <p>
            <span className="font-semibold text-purple-300">School:</span>{" "}
            <span className="font-semibold text-white">
              {childInfo.schoolName}
            </span>
          </p>
          <p>
            <span className="font-semibold text-yellow-300">Class:</span>{" "}
            <span className="font-semibold text-white">{childInfo.class}</span>
          </p>
          <p>
            <span className="font-semibold text-red-300">Blood Group:</span>{" "}
            <span className="font-semibold text-white">
              {childInfo.bloodGroup}
            </span>
          </p>

          {/* Medical and Allergy Information */}
          <p>
            <span className="font-semibold text-red-400">
              Medical Conditions:
            </span>{" "}
            <span className="font-semibold text-white">
              {childInfo.medicalConditions.length
                ? childInfo.medicalConditions.join(", ")
                : "None"}
            </span>
          </p>
          <p>
            <span className="font-semibold text-red-400">Allergies:</span>{" "}
            <span className="font-semibold text-white">
              {childInfo.allergies.length
                ? childInfo.allergies.join(", ")
                : "None"}
            </span>
          </p>

          {/* Parent's Contact */}
          <h2 className="text-2xl font-bold text-white mb-4">
            Parents' Contact
          </h2>
          <PhoneLink label="Father" phoneNumber={childInfo.fatherNumber} />
          <PhoneLink label="Mother" phoneNumber={childInfo.motherNumber} />

          {/* Other Contact */}
          {childInfo.otherName && childInfo.otherNumber && (
            <>
              <h2 className="text-2xl font-bold text-white mb-4">
                Other Contact
              </h2>
              <PhoneLink
                label={childInfo.otherName}
                phoneNumber={childInfo.otherNumber}
              />
            </>
          )}

          {/* Address */}
          {childInfo.address && (
            <p>
              <span className="font-semibold text-blue-300">Address:</span>{" "}
              <span className="font-semibold text-white">
                {childInfo.address}
              </span>
            </p>
          )}

          {/* Emergency Contacts Section */}
          <h2 className="text-2xl font-bold text-white mb-4">
            <span className="text-red-600">Emergency</span> Contacts
          </h2>
          <div className="grid grid-cols-2 gap-2 mt-6">
            <EmergencyContact
              label="Ambulance"
              phoneNumber="108"
              icon={<FiPhoneCall className="mr-2 h-8 w-8 text-red-500" />}
            />
            <EmergencyContact
              label="Police"
              phoneNumber="100"
              icon={<FiPhoneCall className="mr-2 h-8 w-8 text-blue-500" />}
            />
            <EmergencyContact
              label="Fire"
              phoneNumber="101"
              icon={<FiPhoneCall className="mr-2 h-8 w-8 text-orange-500" />}
            />
            <EmergencyContact
              label="Women Help"
              phoneNumber="181"
              icon={<FiPhoneCall className="mr-2 h-8 w-8 text-green-500" />}
            />
          </div>
        </div>
      )}
    </div>
  );
}

const PhoneLink = ({ label, phoneNumber }) => {
  if (!phoneNumber) return null; // Do not render anything if phoneNumber is not available
  return (
    <p>
      <span className="font-semibold text-green-300">{label}:</span>
      <a
        href={`tel:${phoneNumber}`}
        className="text-blue-500 hover:text-blue-700 inline-flex items-center bg-white w-full p-2 rounded-lg text-sm"
      >
        <MdPhone className="mr-2 h-5 w-5" />
        Call Now
      </a>
    </p>
  );
};

const EmergencyContact = ({ label, phoneNumber, icon }) => {
  return (
    <a
      href={`tel:${phoneNumber}`}
      className="text-white grid grid-cols-3 hover:bg-gray-800 bg-[#004d81] p-3 rounded-lg items-center justify-center text-base transition duration-300 ease-in-out transform hover:scale-105"
    >
      {icon}
      <span>{label}</span>
    </a>
  );
};

export default ChildLocketInfoPage;
