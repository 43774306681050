import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import InfoPage from "./pages/InfoPage";
import FormPage from "./pages/FormPage";
import DownloadPage from "./pages/DownloadPage";
import AboutUsPage from "./pages/AboutUsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import KeyChainInfoPage from "./pages/KeyChianInfo";
import DeletePolicy from "./pages/DeletePolicy";
import Header from "./Container/Header";
import ContactUsPage from "./pages/ContactUsPage";
import Footer from "./Container/Footer";
import ServiceDetailPage from "./pages/ServiceDetailPage";
import VehicleInfoPage from "./pages/VehicleInfoPage";
import ChildLocketInfoPage from "./pages/ChildLocketInfoPage";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/vehicle/:QRID" element={<VehicleInfoPage />} />
          <Route path="/info/:QRID" element={<InfoPage />} />
          <Route path="/child-locket" element={<ChildLocketInfoPage />} />
          <Route path="/form/:tempId" element={<FormPage />} />
          <Route path="/download" element={<DownloadPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/delete-policy" element={<DeletePolicy />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/service/:serviceId" element={<ServiceDetailPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
